@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.App {
  text-align: center;
  background-image: url('background.webp');
  background-position: center center; /* Center the background image */
  background-size: cover; /* Scale the background image to be as large as possible */
  background-attachment: fixed; /* Optional: Fix the background image during scroll */
  background-repeat: no-repeat; /* Do not repeat the background image */
  width: 100%;
  height: 100%;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
}

.App-logo {
  height: 40%;
  margin-top: 60px;
  margin-bottom: 20px;
  max-height: 100px;
  pointer-events: none;
}



.App-header {
  
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  color: white;
}

.App-main {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.App-main > button {
  border: 2px solid #040706;
  background: transparent;
  color: #040706;
  border-radius: 2px;
  padding: 8px 14px;
  font-size: 14px;
  font-family: "Open Sans", sans-serif;
  font-weight: bold;
}


.App-footer {
  
  display: flex;
  
}

.footer-logo {
  height: 20px;
  margin: 10px;
}

.App-link {
  color: #61dafb;
}


